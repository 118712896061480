<template>
  <div class="border-ws">
    <div class="border-box-body">
      <div class="border-box-body-hd">
        <van-row type="flex" justify="space-between">
          <van-col span="7">
            <div class="text-left">
              第
              <span>{{ kjObj.period }}</span
              >期
            </div>
          </van-col>
          <van-col span="11">
            <div class="text-left lottys" style="display: flex">
              <div>距下期开奖：</div>
              <div class="van-count-down">
                {{ hours >= 10 ? hours : '0' + hours }}:{{ minutes >= 10 ? minutes : '0' + minutes }}:{{
                  seconds >= 10 ? seconds : '0' + seconds
                }}
              </div>
            </div>
          </van-col>
          <van-col span="6">
            <div class="text-right" @click="goPage('lottery', kjObj.category_id)">
              <span>查看历史记录</span>
            </div>
          </van-col>
        </van-row>
      </div>
      <div class="border-box-body-bd">
        <van-row>
          <van-col v-for="(item, index) in codeList" :key="index" class="tp left">
            <div
              :style="{
                backgroundImage: `url(${
                  item.color == 1
                    ? require('@/assets/images/number-icon-1.png')
                    : item.color == 2
                    ? require('@/assets/images/number-icon-2.png')
                    : item.color == 3
                    ? require('@/assets/images/number-icon-3.png')
                    : ''
                })`,
              }"
              class="icon-text bg-amage-3"
            >
              <div>
                <span class="slot-icon">
                  <span>{{ item.number }}</span>
                </span>
              </div>
              <div class="slot-text">
                {{ item.sx !== '/' ? item.sx : '' }}
              </div>
            </div>
          </van-col>

          <van-col class="tc">
            <div class="connect">
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none">
                <g clip-path="url(#clip0_2963_55717)">
                  <path
                    d="M1.42856 6H11.4286"
                    stroke="#999999"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M6.42856 1V11"
                    stroke="#999999"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2963_55717">
                    <rect width="12" height="12" fill="white" transform="translate(0.428558)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </van-col>
          <van-col v-for="(item, i) in codeLsit7" :key="item.number" class="tp right">
            <div
              :style="{
                backgroundImage: `url(${
                  item.color == 1
                    ? require('@/assets/images/number-icon-1.png')
                    : item.color == 2
                    ? require('@/assets/images/number-icon-2.png')
                    : item.color == 3
                    ? require('@/assets/images/number-icon-3.png')
                    : ''
                })`,
              }"
              class="icon-text bg-amage-2"
            >
              <div>
                <span class="slot-icon">
                  <span>{{ item.number }}</span>
                </span>
              </div>
              <div class="slot-text">
                {{ item.sx !== '/' ? item.sx : '' }}
              </div>
            </div>
          </van-col>
        </van-row>
      </div>
      <div class="border-box-body-ft">
        第{{ kjObj.period ? +kjObj.period + 1 : '' }}期 {{ kjObj.next_open_time }} 星期{{ weekDay }}
      </div>
    </div>
  </div>
</template>

<script>
import lv from '@/assets/images/number-icon-3.png'
import lan from '@/assets/images/number-icon-2.png'
import hong from '@/assets/images/number-icon-1.png'
const yanse = {
  红波: '01,02,07,08,12,13,18,19,23,24,29,30,34,35,40,45,46',
  蓝波: '03,04,09,10,14,15,20,25,26,31,36,37,41,42,47,48',
  绿波: '05,06,11,16,17,21,22,27,28,32,33,38,39,43,44,49',
}
const wuxing = {
  金: '02,03,10,11,24,25,32,33,40,41',
  木: '06,07,14,15,22,23,36,37,44,45',
  水: '12,13,20,21,28,29,42,43',
  火: '01,08,09,16,17,30,31,38,39,46,47',
  土: '04,05,18,19,26,27,34,35,48,49',
}
const shengXiao = {
  龙: '01,13,25,37,49',
  兔: '02,14,26,38',
  虎: '03,15,27,39',
  牛: '04,16,28,40',
  鼠: '05,17,29,41',
  猪: '06,18,30,42',
  狗: '07,19,31,43',
  鸡: '08,20,32,44',
  猴: '09,21,33,45',
  羊: '10,22,34,46',
  马: '11,23,35,47',
  蛇: '12,24,36,48',
}

// 获取对应的 key
function getKeyFromObject(obj, number) {
  const numStr = number.toString().padStart(2, '0') // 确保是两位数字
  for (const [key, value] of Object.entries(obj)) {
    if (value.split(',').includes(numStr)) {
      return key
    }
  }
  return null // 如果没有找到，返回 null
}

export default {
  props: {
    kjObj: {
      type: Object,
      default: () => ({}),
    },
  },
  // components: {},
  data() {
    return {
      codeList: [],
      codeLsit7: [],
      time: 0,
      weekDay: '',
      targetDate: '',
      seconds: '',
      minutes: '',
      hours: '',
      days: '',
      timer: null,
    }
  },
  watch: {
    kjObj(newVal, oldVal) {
      // console.log(newVal, oldVal, '12355555')
      if (newVal && newVal.number) {
        // console.log(newVal.number.split(','))

        if (this.timer) {
          clearInterval(this.timer)
        }
        const code = newVal.number.split(',')
        const sx = newVal.shengXiao.split(',')
        const wx = newVal.wuXing.split(',')
        const color = newVal.color.split(',')
        // console.log(code, sx, wx, color)

        let list = code.map((item, index) => {
          return {
            number: item,
            sx: sx[index] + '/' + wx[index],
            color: color[index],
          }
        })
        // 截取前6个
        this.codeList = list.slice(0, 6)
        this.codeLsit7 = list.slice(6, 7)
        // console.log(newVal.next_open_time)s

        // 倒计时
        this.targetDate = new Date(newVal.next_open_time)
        // 计算星期几
        let daysOfWeek = ['日', '一', '二', '三', '四', '五', '六']
        this.weekDay = daysOfWeek[this.targetDate.getUTCDay()] // 获取星期几
        // 每秒更新倒计时
        this.timer = setInterval(() => {
          // console.log(555555)

          this.getCountdown()
        }, 1000)
      }
    },
  },
  // mounted: {},
  methods: {
    getCountdown() {
      const now = new Date()
      const timeDifference = this.targetDate - now
      // console.log(timeDifference, 1233214)
      if (timeDifference < 0) {
        // console.log(1111)

        return '时间已过'
      }
      // console.log(333)
      const totalSeconds = Math.floor(timeDifference / 1000)
      this.seconds = totalSeconds % 60
      this.minutes = Math.floor((totalSeconds % 3600) / 60)
      this.hours = Math.floor(totalSeconds / 3600)
      // this.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

      // return `${days}: ${hours}: ${minutes}: ${seconds}`
      if (this.hours == 0 && this.minutes == 0 && this.seconds == 0) {
        this.$emit('updatekj', false)
        if (this.timer) {
          clearInterval(this.timer)
        }
      }
    },
    openkj(newVal) {
      // console.log(newVal.number.split(','))
      // console.log(1243245)

      if (this.timer) {
        clearInterval(this.timer)
      }
      const code = newVal.number.split(',')
      const sx = newVal.shengXiao.split(',')
      const wx = newVal.wuXing.split(',')
      const color = newVal.color.split(',')
      // console.log(code, sx, wx, color)

      let list = code.map((item, index) => {
        return {
          number: item,
          sx: sx[index] + '/' + wx[index],
          color: color[index],
        }
      })
      if (this.areAllValidNumbers(list)) {
        this.$emit('updatekj', true)
      }

      // 截取前6个
      this.codeList = list.slice(0, 6)
      this.codeLsit7 = list.slice(6, 7)
      // console.log(this.codeList, this.codeLsit7)

      // 倒计时
      this.targetDate = new Date(newVal.next_open_time)
      // 计算星期几
      let daysOfWeek = ['日', '一', '二', '三', '四', '五', '六']
      this.weekDay = daysOfWeek[this.targetDate.getUTCDay()] // 获取星期几
      // 每秒更新倒计时
      this.timer = setInterval(() => {
        // console.log(555555)

        this.getCountdown()
      }, 1000)
    },
    areAllValidNumbers(arr) {
      return arr.every(item => !isNaN(Number(item)) && item.trim() !== '')
    },
    goSearch() {
      // this.$router.push('/homeSearch')
    },
    goPage(val, id) {
      this.$router.push({
        path: val,
        query: {
          id: id,
        },
      })
    },
    onSelect(option) {
      // Toast(option.name);
      // this.showShare = false
    },
    changeLotteryType(val) {
      // this.lotteryType = val
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // setTimeout(() => {
      //   for (let i = 0; i < 10; i++) {
      //     this.list.push(this.list.length + 1)
      //   }
      //   // 加载状态结束
      //   this.loading = false
      //   // 数据全部加载完成
      //   if (this.list.length >= 40) {
      //     this.finished = true
      //   }
      // }, 1000)
    },
  },
}
</script>

<style>
.border-ws {
  background: #fff;
  padding: 0rem 0.16rem;
  padding-top: 0;
}
.border-ws .border-box-body {
  position: relative;
  background-size: 100% 100%;
  padding: 0.24rem 0.16rem;
  /* border-radius: 10px; */
  border: 0.01rem solid #da1b2d;
}
.border-ws .border-box-body .border-box-body-hd {
  padding-bottom: 0.2rem;
}
.border-ws .border-box-body .border-box-body-hd .text-left {
  text-align: left;
  font-size: 0.28rem;
  font-style: normal;
  font-weight: 400;
}
.border-ws .border-box-body .border-box-body-hd .text-left span {
  color: #da1b2d;
  font-size: 0.28rem;
  font-style: normal;
  font-weight: 400;
}
.van-count-down {
  color: #323233;
  font-size: 14px;
  line-height: 20px;
}
.border-ws .border-box-body .border-box-body-hd .text-right {
  text-align: right;
  white-space: nowrap;
}
.border-ws .border-box-body .border-box-body-hd .text-right a,
.border-ws .border-box-body .border-box-body-hd .text-right span {
  color: #da1b2d;
  font-size: 0.28rem;
  font-style: normal;
  font-weight: 400;
}
.border-ws .border-box-body .border-box-body-bd .tp {
  width: 13.5%;
}
.icon-text {
  display: inline-block;
  text-align: center;
  position: relative;
}
.border-ws .border-box-body .icon-text {
  height: 0.88rem;
  width: auto;
}
.border-ws .border-box-body .icon-text.bg-amage-1 {
  background-image: url('@/assets/images/number-icon-1.png');
  background-size: 100% 100%;
}
.border-ws .border-box-body .icon-text.bg-amage-2 {
  background-image: url('@/assets/images/number-icon-2.png');
  background-size: 100% 100%;
}
.border-ws .border-box-body .icon-text.bg-amage-3 {
  /* background-image: url('@/assets/images/number-icon-3.png'); */
  background-size: 100% 100%;
}
.border-ws .border-box-body .slot-icon {
  display: inline-block;
  width: 0.88rem;
  line-height: 0.88rem;
  font-size: 0.4rem;
  font-weight: 700;
  color: #000;
  text-indent: 0.08rem;
}
.border-ws .border-box-body .slot-icon span {
  padding-right: 0.06rem;
}
.border-ws .border-box-body .slot-text {
  font-size: 0.24rem;
  color: #333;
  margin: 0.08rem;
  padding: 0.04rem;
  border-radius: 0.04rem;
  background-color: #fff;
}
.border-ws .border-box-body .connect {
  padding-top: 0.4rem;
  text-align: center;
}
.border-ws .border-box-body .border-box-body-ft {
  text-align: center;
  padding-top: 0.2rem;
  color: #666;
  font-family: Noto Sans SC;
  font-size: 0.24rem;
  font-style: normal;
  font-weight: 400;
}
</style>
