import http from '../utils/http'
//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */

// get请求
const testApi = {
  // 首页
  getIndexAPI(params) {
    return http.get('/api/index/index', params)
  },
  getKjAPI(id) {
    return http.get('/api/index/lastLotteryRecord?category_id=' + id)
  },
  // 推荐站点
  getTjZdAPI(id) {
    return http.get('/api/index/listWebsite?category_id=' + id)
  },
  // 首页图片列表
  getImgAPI(id = 2, page, picture_series_id, color, keyword) {
    return http.get(
      '/api/picture/listPicture?category_id=' +
        id +
        `${page ? '&page=' + page : ''}${picture_series_id ? '&picture_series_id=' + picture_series_id : ''}${
          color ? '&color=' + color : ''
        }${keyword ? '&keyword=' + keyword : ''}`
    )
  },
  // 获取图库系列
  getlistPictureSeriesAPI(id, color, keyword) {
    return http.get(
      '/api/picture/listPictureSeries?category_id=' +
        id +
        `${color ? '&color=' + color : ''}${keyword ? '&keyword=' + keyword : ''}`
    )
  },
  // 获取视频列表
  getVideoAPI(id = 1, page, limit) {
    return http.get(
      '/api/index/listLotteryVideo?category_id=' +
        id +
        `${page ? '&page=' + page : ''}${limit ? '&limit=' + limit : ''}`
    )
  },
  // 获取视频推荐
  getVideoTjAPI(id) {
    return http.get('/api/index/listLotteryRecommend?category_id=' + id)
  },
  // 图片详情
  getImgDesAPI({ category_id, year, color, keyword }) {
    console.log(category_id, year, color, keyword)

    return http.get(
      '/api/index/search' +
        `${category_id ? '?category_id=' + category_id : ''}${
          year ? (category_id ? '&year=' + year : '?year=' + year) : ''
        }${color ? '&color=' + color : ''}${keyword ? '&keyword=' + keyword : ''}`
    )
  },
  // 获取年份列表
  getYearAPI(category_id) {
    return http.get('/api/index/listYear?category_id=' + category_id)
  },
  // 开奖历史
  getLotteryHistoryAPI(id, page, sort, year) {
    return http.get(
      `/api/index/History?category_id=` +
        id +
        `${page ? '&page=' + page : ''}${sort ? '&sort=' + sort : ''}${year ? '&year=' + year : ''}`
    )
  },
  // 公告
  getNoticeAPI(status, page) {
    return http.get(`/api/index/news?status=${status}&page=${page}`)
  },
}
export default testApi
