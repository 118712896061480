import http from '../utils/http'
const Api = {
  //等级列表
  getLevelListApi() {
    return http.get(`/api/user/level`)
  },
  // 我的收藏列表
  getCollectListApi(type, category_id, page) {
    return http.get(`/api/collect/list?type=${type}&category_id=${category_id}&page=${page}`)
  },
  // 我的点赞
  getLikeListApi(category_id, page) {
    return http.get(`/api/like/my_list?category_id=${category_id}&page=${page}`)
  },
  // 我的关注
  getFollowListApi(page) {
    return http.get(`/api/attention/my_attention?page=${page}`)
  },
  // 关注
  followApi(attention_id) {
    return http.get(`/api/attention/index?attention_id=${attention_id}`)
  },
  // 判断是否关注
  isFollowApi(attention_id) {
    return http.get(`/api/attention/is_attention?attention_id=${attention_id}`)
  },
  // 我的粉丝
  getFansListApi(page) {
    return http.get(`/api/attention/my_fans?page=${page}`)
  },
  // 粉丝排行榜 /api/index/fan_rankings
  getFansRankingsApi() {
    return http.get(`/api/index/fan_rankings`)
  },
  // 等级排行榜 /api/index/level_rankings
  getLevelRankingsApi() {
    return http.get(`/api/index/level_rankings`)
  },
  // 金币排行榜 /api/index/gold_rankings
  getGoldRankingsApi() {
    return http.get(`/api/index/gold_rankings`)
  },
  // 分享排行榜
  getShareRankingsApi() {
    return http.get(`/api/user/share_list`)
  },
  // 公告
  getNoticeApi(status, page) {
    return http.get(`/api/index/news?status=${status}&page=${page}`)
  },
  // 提现记录
  getWithdrawListApi(type, page) {
    return http.get(`/api/user/user_money_log?page=${page}&type=${type}`)
  },
  // 我的评论
  getCommentListApi(category_id, page) {
    return http.get(`/api/comment/my_list?category_id=${category_id}&page=${page}`)
  },
  // 我的发布
  getPublishListApi(category_id, user_id, page) {
    return http.get(`/api/forum/user_forum?category_id=${category_id}&page=${page}&user_id=${user_id}`)
  },
  // 我的推广
  getPromoteListApi(page) {
    return http.get(`/api/user/promotion?page=${page}`)
  },
}
export default Api
