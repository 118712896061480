var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-ws"},[_c('div',{staticClass:"border-box-body"},[_c('div',{staticClass:"border-box-body-hd"},[_c('van-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('van-col',{attrs:{"span":"7"}},[_c('div',{staticClass:"text-left"},[_vm._v(" 第 "),_c('span',[_vm._v(_vm._s(_vm.kjObj.period))]),_vm._v("期 ")])]),_c('van-col',{attrs:{"span":"11"}},[_c('div',{staticClass:"text-left lottys",staticStyle:{"display":"flex"}},[_c('div',[_vm._v("距下期开奖：")]),_c('div',{staticClass:"van-count-down"},[_vm._v(" "+_vm._s(_vm.hours >= 10 ? _vm.hours : '0' + _vm.hours)+":"+_vm._s(_vm.minutes >= 10 ? _vm.minutes : '0' + _vm.minutes)+":"+_vm._s(_vm.seconds >= 10 ? _vm.seconds : '0' + _vm.seconds)+" ")])])]),_c('van-col',{attrs:{"span":"6"}},[_c('div',{staticClass:"text-right",on:{"click":function($event){return _vm.goPage('lottery', _vm.kjObj.category_id)}}},[_c('span',[_vm._v("查看历史记录")])])])],1)],1),_c('div',{staticClass:"border-box-body-bd"},[_c('van-row',[_vm._l((_vm.codeList),function(item,index){return _c('van-col',{key:index,staticClass:"tp left"},[_c('div',{staticClass:"icon-text bg-amage-3",style:({
              backgroundImage: ("url(" + (item.color == 1
                  ? require('@/assets/images/number-icon-1.png')
                  : item.color == 2
                  ? require('@/assets/images/number-icon-2.png')
                  : item.color == 3
                  ? require('@/assets/images/number-icon-3.png')
                  : '') + ")"),
            })},[_c('div',[_c('span',{staticClass:"slot-icon"},[_c('span',[_vm._v(_vm._s(item.number))])])]),_c('div',{staticClass:"slot-text"},[_vm._v(" "+_vm._s(item.sx !== '/' ? item.sx : '')+" ")])])])}),_c('van-col',{staticClass:"tc"},[_c('div',{staticClass:"connect"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"13","height":"12","viewBox":"0 0 13 12","fill":"none"}},[_c('g',{attrs:{"clip-path":"url(#clip0_2963_55717)"}},[_c('path',{attrs:{"d":"M1.42856 6H11.4286","stroke":"#999999","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M6.42856 1V11","stroke":"#999999","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('defs',[_c('clipPath',{attrs:{"id":"clip0_2963_55717"}},[_c('rect',{attrs:{"width":"12","height":"12","fill":"white","transform":"translate(0.428558)"}})])])])])]),_vm._l((_vm.codeLsit7),function(item,i){return _c('van-col',{key:item.number,staticClass:"tp right"},[_c('div',{staticClass:"icon-text bg-amage-2",style:({
              backgroundImage: ("url(" + (item.color == 1
                  ? require('@/assets/images/number-icon-1.png')
                  : item.color == 2
                  ? require('@/assets/images/number-icon-2.png')
                  : item.color == 3
                  ? require('@/assets/images/number-icon-3.png')
                  : '') + ")"),
            })},[_c('div',[_c('span',{staticClass:"slot-icon"},[_c('span',[_vm._v(_vm._s(item.number))])])]),_c('div',{staticClass:"slot-text"},[_vm._v(" "+_vm._s(item.sx !== '/' ? item.sx : '')+" ")])])])})],2)],1),_c('div',{staticClass:"border-box-body-ft"},[_vm._v(" 第"+_vm._s(_vm.kjObj.period ? +_vm.kjObj.period + 1 : '')+"期 "+_vm._s(_vm.kjObj.next_open_time)+" 星期"+_vm._s(_vm.weekDay)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }