<template>
  <div id="app">
    <keep-alive :include="['IndexView', 'RecordView']">
      <router-view />
    </keep-alive>
  </div>
</template>
<script>
import axios from '@/api/activity'
// import { mapState, mapGetters } from 'vuex'
export default {
  name: 'App',

  data() {
    return {
      timerNum: 0,
      componentKey: 0,
    }
  },
  // computed: {
  //   ...mapGetters(['getRefreshHomePage']), // 获取 refreshHomePage 状态
  // },
  // watch: {
  //   getRefreshHomePage(newValue) {
  //     if (newValue) {
  //       // 当刷新标志为 true 时，执行刷新操作
  //       this.refreshHome()
  //       // 刷新后，重置为 false，防止重复刷新
  //       this.$store.dispatch('triggerRefreshHomePage', false)
  //     }
  //   },
  // },
  created() {
    // console.log(this.$route.name, 'this.$route')

    this.getData()
  },
  mounted() {
    document.addEventListener(
      'touchstart',
      function (event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      },
      { passive: false }
    )
    console.log(this.$store, 'this.$store')
  },
  methods: {
    getData() {
      axios.signInActivity().then(res => {
        // console.log(res, 'sdfsdf')
        if (res.code == 1) {
          sessionStorage.setItem('zaixianTime', res.data.task_online_num)
          // sessionStorage.setItem('jinbiNum', res.data.task_online_gold)
        }
      })
    },
    // refreshHome() {
    //   console.log('首页已刷新！')
    //   // 这里可以执行刷新首页的操作，例如重新加载数据，强制更新组件等
    //   this.$forceUpdate() // 强制更新页面
    // },
  },
}
</script>
<style lang="less">
@import '@/assets/css/global.css';
</style>
